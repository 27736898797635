import { graphql } from "gatsby"
import { gsap } from "gsap"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { Helmet } from "react-helmet"
import { IntlProvider } from "react-intl"

import ogImage from "@/assets/images/seo/ogimageEn.jpg"
import BackgroundWall from "@/components/BackgroundWall/BackgroundWall"
import Footer from "@/components/Footer/Footer"
import IntroSection from "@/components/IntroSection/IntroSection"
import Layout from "@/components/Layout/Layout"
import Navbar from "@/components/Navbar/Navbar"
import Seo from "@/components/Seo/Seo"
import SpeakerSection from "@/components/SpeakerSection/SpeakerSection"
import StickyMobileCta from "@/components/StickyMobileCta"
import en from "@/intl/en"

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollToPlugin)
}

const IndexPage = ({ data }) => {
  const speakerImages = data.allFile.edges
  const spearkersSectionRef = useRef(null)
  const formRef = useRef(null)
  const [showNav, seShowNav] = useState(false)

  useEffect(() => {
    const onScroll = () => {
      let currentPosition = window.pageYOffset
      let windowSize = window.innerHeight + 100
      if (currentPosition > windowSize) {
        seShowNav(true)
      } else {
        seShowNav(false)
      }
    }

    window.addEventListener("scroll", onScroll)
    return () => window.removeEventListener("scroll", onScroll)
  }, [showNav])

  const scrolltoConf = () => {
    gsap.to(window, {
      duration: 1,
      scrollTo: {
        y: spearkersSectionRef.current,
      },
      overwrite: true,
      ease: "linear",
    })
  }

  const scrolltoForm = () => {
    gsap.to(window, {
      duration: 1,
      scrollTo: {
        y: formRef.current,
      },
      overwrite: true,
      ease: "linear",
    })
  }

  return (
    <IntlProvider locale="en" messages={en}>
      <Helmet>
        <meta
          name="facebook-domain-verification"
          content="0njh37hxzi11zz2kg5f3wem68cb7xu"
        />
      </Helmet>
      <Seo
        title="Content Summit 2022 | PlayPlay’s Content Summit is THE event for comms and marketing pros"
        desc="On Sept 22, join industry's crème de la crème and sharpen your content skills for 2023. 100% virtual, 100% free!"
        ogImage={ogImage}
      />
      <Layout>
        {showNav && (
          <Navbar scrolltoConf={scrolltoConf} scrolltoForm={scrolltoForm} />
        )}
        <IntroSection scrolltoConf={scrolltoConf} formRef={formRef} />
        <SpeakerSection
          scrolltoConf={scrolltoConf}
          speakerImages={speakerImages}
          spearkersSectionRef={spearkersSectionRef}
        />
        <Footer />
        {/* {showNav && <StickyMobileCta scrolltoForm={scrolltoForm} />} */}
        <BackgroundWall />
      </Layout>
    </IntlProvider>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allFile(filter: { relativeDirectory: { eq: "images/speakers" } }) {
      edges {
        node {
          name
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              transformOptions: { fit: COVER }
              width: 720
            )
          }
        }
      }
    }
  }
`

IndexPage.propTypes = {
  data: PropTypes.object,
}
